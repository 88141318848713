import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import type { TBrandListData } from '@/common';
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/atoms';
import { cn } from '@/lib/utils';
import { LINKS, getLink } from '@/utils/getLink';

export function HomeCarousel() {
  const t = useTranslations();

  const bannerBrandListData = t.raw('bannerBrandListData') as TBrandListData[];

  return (
    <div className="w-full bg-bg-body">
      <div className="px-4 py-10 lg:px-[100px]">
        <h2 className="h1 mb-6 text-center sm:mb-10">{t('ourFamilyOfBrands')}</h2>
        <Carousel className="w-full max-w-full" hasDots>
          <CarouselContent className="-ml-6 sm:-ml-10">
            {bannerBrandListData.map((brand) => (
              <CarouselItem
                key={brand.id}
                className={cn(
                  'w-[184px] max-w-[184px] px-0 pl-6 lg:w-[200px] lg:max-w-[200px] lg:pl-10'
                )}
              >
                <Link
                  href={getLink('link', { url: brand.url, hasHtml: false })}
                  prefetch={false}
                  shallow={true}
                >
                  <div className="mb-4 overflow-hidden rounded-full border-[0.625rem] border-blue-50 duration-150 hover:border-blue-100">
                    <Image
                      src={brand.image}
                      alt="carousel image"
                      width={400}
                      height={400}
                      priority
                    />
                  </div>

                  <Image
                    loading="lazy"
                    src={brand.logo}
                    width={400}
                    height={200}
                    alt={brand.logo}
                    className="mx-auto h-auto w-full"
                  />
                </Link>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="top-[27%] hidden lg:flex" size="lg" />
          <CarouselNext className="top-[27%] hidden lg:flex" size="lg" />
        </Carousel>

        <div className="mt-6 flex w-full items-center justify-center">
          <Link href={LINKS.signUp} className="w-full sm:w-fit" prefetch={false} shallow={true}>
            <Button color="secondary" className="mx-auto w-full sm:w-fit" size="lg">
              {t('createAFreeAccount')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
