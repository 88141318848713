import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@/components/atoms';
import { Divider } from '@/components/atoms/Divider';
import { COMPANY_INFO } from '@/constants/companyInfo';
import { LINKS } from '@/utils/getLink';

export function Stats() {
  const t = useTranslations();

  return (
    <section className="w-full bg-white py-10 sm:py-20">
      <div className="container flex flex-col gap-6 sm:gap-10">
        <h1 className="h1 text-center">{t('stats.title')}</h1>
        <div className="flex flex-col justify-between gap-2 lg:flex-row">
          <div className="flex flex-col gap-2 text-center">
            <h2 className="display-large">{COMPANY_INFO.totalCustomers}</h2>
            <p className="body1-regular text-fg-weak">{t('stats.stat1')}</p>
          </div>
          <Divider className="hidden h-28 w-1 lg:block" orientation="vertical" />
          <Divider className="h-1 w-full lg:hidden" orientation="horizontal" />

          <div className="flex flex-col gap-2 text-center">
            <h2 className="display-large">{COMPANY_INFO.totalYears}</h2>
            <p className="body1-regular text-fg-weak">{t('stats.stat2')}</p>
          </div>
          <Divider className="hidden h-28 w-1 lg:block" orientation="vertical" />
          <Divider className="h-1 w-full lg:hidden" orientation="horizontal" />
          <div className="flex flex-col gap-2 text-center">
            <h2 className="display-large">{COMPANY_INFO.totalGifts}</h2>
            <p className="body1-regular text-fg-weak">{t('stats.stat3')}</p>
          </div>
          <Divider className="hidden h-28 w-1 lg:block" orientation="vertical" />
          <Divider className="h-1 w-full lg:hidden" orientation="horizontal" />
          <div className="flex flex-col gap-2 text-center">
            <h2 className="display-large">{COMPANY_INFO.totalBrands}</h2>
            <p className="body1-regular text-fg-weak">{t('stats.stat4')}</p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-4 lg:flex-row">
          <Link href={LINKS.signUp} className="w-full lg:w-fit" prefetch={false} shallow={true}>
            <Button className="w-full lg:w-fit" color="secondary" size="lg">
              {t('createAFreeAccount')}
            </Button>
          </Link>
          <Link
            href={LINKS.talkToAnExpert}
            className="w-full lg:w-fit"
            prefetch={false}
            shallow={true}
          >
            <Button className="w-full lg:w-fit" color="secondary" size="lg" variant="outlined">
              {t('talkToAnExpert')}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
