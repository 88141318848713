import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { LINKS } from '@/utils/getLink';
import { Button } from '../../atoms';
import { RoleCircleItem } from '../RoleCircleItem';

const orders = {
  order1: 'relative sm:absolute sm:top-0 sm:left-[35%] sm:transform sm:-translate-x-1/2',
  order2: 'relative sm:absolute sm:top-0 sm:left-[70%] sm:transform sm:-translate-x-1/2',
  order3: 'relative sm:absolute sm:top-[50%] sm:-right-[10%] sm:transform sm:-translate-y-1/2',
  order4:
    'relative sm:absolute bottom-0 sm:right-[30%] lg:right-[35%] sm:transform sm:translate-x-1/2',
  order5:
    'relative sm:absolute bottom-0 sm:left-[30%] lg:left-[30%] sm:transform sm:-translate-x-1/2',
  order6:
    'relative sm:absolute sm:bottom-[unset] sm:top-[50%] sm:-left-[5%] sm:transform sm:-translate-y-1/2',
};
const roles = [
  {
    prefix: 'in',
    name: 'Human Resources',
    avatarUrl: '/images/common/role/human-resources.webp',
    avatarBg: 'bg-yellow-50',
    bgColor: 'bg-yellow-100',
    borderBg: 'border-yellow-300',
    className: orders.order1,
  },
  {
    prefix: 'on a',
    name: 'Sales Team',
    avatarUrl: '/images/common/role/sales-teams.webp',
    avatarBg: 'bg-green-25',
    bgColor: 'bg-green-100',
    borderBg: 'border-green-300',
    className: orders.order2,
  },
  {
    prefix: 'an',
    name: 'Executive Assistant',
    avatarUrl: '/images/common/role/executive-assistant.webp',
    avatarBg: 'bg-neutral-50',
    bgColor: 'bg-neutral-100',
    borderBg: 'border-neutral-300',
    className: orders.order3,
  },

  {
    prefix: 'in',
    name: 'Customer Success',
    avatarUrl: '/images/common/role/customer-success.webp',
    avatarBg: 'bg-blue-25',
    bgColor: 'bg-blue-50',
    borderBg: 'border-blue-300',
    className: orders.order4,
  },
  {
    prefix: 'in',
    name: 'Marketing',
    avatarUrl: '/images/common/role/marketing-teams.webp',
    avatarBg: 'bg-pink-50',
    bgColor: 'bg-pink-100',
    borderBg: 'border-pink-300',
    className: orders.order5,
  },
  {
    prefix: 'a',
    name: 'Team Lead',
    avatarUrl: '/images/common/role/team-leads.webp',
    avatarBg: 'bg-purple-50',
    bgColor: 'bg-purple-100',
    borderBg: 'border-purple-300',
    className: orders.order6,
  },
];

export function RoleCircle() {
  const t = useTranslations();

  return (
    <section className="w-full bg-white py-10">
      <div className="container flex flex-col justify-center">
        <h2 className="h1 text-center">{t('areYou')}...</h2>
        <div className="relative my-6 flex w-full flex-wrap justify-evenly gap-4 sm:my-10 sm:h-[25rem] lg:mx-auto lg:w-[80%]">
          <div className="absolute top-[15%] right-0 bottom-0 left-0 mx-auto hidden h-[70%] w-[100%] rounded-[100%] border border-light-blue-700 border-dashed sm:block" />

          {roles.map((role) => (
            <RoleCircleItem
              key={role.name}
              {...role}
              id={`circle-${role.name}`}
              className={`group flex h-36 w-36 flex-col items-center rounded-full shadow-lg duration-150 hover:scale-105 hover:cursor-pointer sm:h-32 sm:w-32 lg:h-[10rem] ${role.className} lg:w-[10rem] ${role.avatarBg} group-hover:paused`}
            />
          ))}
        </div>
        <h2 className="h1 text-center">{t('youAreInRightPlace')}</h2>

        <Button className="mx-auto mt-4 w-full sm:mt-6 sm:w-fit" color="secondary">
          <Link href={LINKS.signUp} prefetch={false} shallow={true}>
            {t('createAFreeAccount')}
          </Link>
        </Button>
      </div>
    </section>
  );
}
